import React, { useEffect, useState } from 'react'
import { Answer, Answers, InputType } from '@/types/types'
import { useTranslation } from 'react-i18next'
import height from '@/assets/images/body-height.svg'
import { getValue } from '@/helpers/getObjectValue'

interface InputProps {
  attributes: InputType
  onAnswer: (answer: Answer, step?: number) => void
  handleRemoveAnswer: (answer: Answer) => void
  name: string
  error: string
  step: number
  disabled?: boolean
  answers: Answers
}

export default function Input(props: InputProps) {
  const { t } = useTranslation()
  const [showError, setShowError] = useState('')
  const [updatedPlaceholder, setUpdatedPlaceholder] = useState('')
  const [inputValue, setInputValue] = useState('')
  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
    let isValid = e.target.checkValidity()
    if (props.attributes.type === 'email') {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      isValid = emailRegex.test(e.target.value)
    }
    if (isValid) {
      props.onAnswer({ [props.name]: [e.target.value] })
      setShowError('')
    } else {
      if (props.attributes.type === 'email') {
        props.onAnswer({ [props.name]: [] })
        setShowError(t('invalidEmail'))
      }
    }
  }

  useEffect(() => {
    const choosedValue = getValue(props.name, props.answers)
    if (choosedValue && typeof choosedValue === 'string') {
      setShowError('')
      setInputValue(choosedValue)
    }
    if (props.name === 'your-age') {
      const placeholder =
        sessionStorage.getItem('placeholderAge') !== null
          ? sessionStorage.getItem('placeholderAge')
          : ''
      setUpdatedPlaceholder(placeholder ?? '')
    }
  }, [])

  useEffect(() => {
    if (props.error) {
      setShowError(props.error)
    }
  }, [props.error])

  return (
    <div>
      {props.attributes.label && (
        <label className="!font-medium text-base opacity-65 mb-1">{props.attributes.label}</label>
      )}
      <input
        value={inputValue}
        type={props.attributes.type}
        inputMode={props.attributes.inputMode === 'numberic' ? 'numeric' : 'text'}
        onChange={(e) => handleChangeInput(e)}
        placeholder={updatedPlaceholder.length ? updatedPlaceholder : props.attributes.placeholder}
        minLength={props.attributes.minLength}
        required={props.attributes.required}
        className={`${showError ? 'border-mark' : ''} ${props.attributes.className ? 'text-xl2 p-3' : 'text-xl6 bg-lightGray text-center p-2 uppercase placeholder:capitalize capitalize'}   block w-full border border-borderInput rounded-r12  font-extrabold outline-none text-dark placeholder:text-darkOpacity mb-5`}
      />
      {showError && (
        <span className="text-mark block mt-1 text-xs3 font-semibold">{showError}</span>
      )}
      {props.attributes.tooltip && (
        <div
          className={`${inputValue.length === 0 ? 'opacity-0' : 'opacity-100'} transition-all p-4 border border-borderInput bg-lightGray mt-8 rounded-r12`}
        >
          <div className="flex items-start justify-start">
            <img
              className="min-w-6 max-w-6 mr-3"
              src={height}
              width="24"
              height="24"
              alt="Height icon"
            />
            <h6 className="font-extrabold text-xl2">
              {t('To gauge your fitness levels accurately')}
            </h6>
          </div>
          <p className="text-base text-dark mt-5 pl-9">
            {t('Older people tend to have more body fat than younger people with the same BMI.')}
          </p>
        </div>
      )}
    </div>
  )
}
