import { getValue } from '@/helpers/getObjectValue'
import { Answer, Answers } from '@/types/types'
import React, { useEffect, useState } from 'react'
import Image from '../Image/Image'
import BaseGraphuUp from '@/assets/images/base-graph-up.png'
import BaseGraphuUpWebp from '@/assets/images/base-graph-up.webp'
import BaseGraphuDown from '@/assets/images/base-graph-down.png'
import BaseGraphuUpDownp from '@/assets/images/base-graph-down.webp'

interface GraphProps {
  active: boolean
  answers: Answers
  onAnswer: (answer: Answer, step?: number) => void
}
export const PurchaseWithName = (props: GraphProps) => {
  const name = getValue('your-name', props.answers)
  const [loadAnimation, setLoadAnimation] = useState(false)
  const currentWeight = getValue('current-weight', props.answers)
  const goalWeight = getValue('goal-weight', props.answers)
  const condition =
    currentWeight && goalWeight && parseInt(currentWeight[0]) < parseInt(goalWeight[0])

  useEffect(() => {
    if (props.active) {
      setTimeout(() => {
        setLoadAnimation(true)
      }, 500)
    } else {
      setLoadAnimation(false)
    }
  }, [props.active])
  return (
    <>
      <h2 className="text-xl35 -mt-4 font-girloy">
        <mark>{name},</mark> <br />
        your 4-week Wall Pilates Workout Plan is ready!
      </h2>
      <Image
        className={`${loadAnimation ? 'active-betterme-graph' : 'opacity-0'} w-[300px] mx-auto`}
        src={condition ? BaseGraphuDown : BaseGraphuUp}
        webp={condition ? BaseGraphuUpDownp : BaseGraphuUpWebp}
        alt="graph"
      />
      <p className="text-base text-[#727272] font-semibold text-center mt-4">
        This chart is for illustrative purposes only
      </p>
    </>
  )
}
