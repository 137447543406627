import { getValue } from '@/helpers/getObjectValue'
import { Answer, Answers } from '@/types/types'
import { Tooltip } from 'react-tooltip'
import React, { useEffect, useMemo, useState } from 'react'

// const eventIcons = {
//   Vacation: '/img/radio/Vacation.svg',
//   Wedding: '/img/radio/Wedding.svg',
//   Holiday: '/img/radio/Holiday.svg',
//   'Sporting event': '/img/radio/Sporting-event.svg',
//   Reunion: '/img/radio/Reunion.svg',
//   Birthday: '/img/radio/Birthday.svg',
//   Other: '/img/radio/Other.svg',
// }

const eventNames = {
  Vacation: 'Vacation',
  Wedding: 'Wedding',
  Holiday: 'Holiday',
  'Sporting Event': 'Sporting Event',
  Reunion: 'Reunion',
  Birthday: 'Birthday',
  Other: 'Event',
}

interface GraphProps {
  active: boolean
  answers: Answers
  onAnswer: (answer: Answer, step?: number) => void
}
export const PersonalizedGraphic = (props: GraphProps) => {
  const currentWeight = getValue('current-weight', props.answers)
  const goalWeight = getValue('goal-weight', props.answers)
  const eventDate = getValue('date-of-event', props.answers)
  const event = getValue('important-event', props.answers)
  const [loadAnimation, setLoadAnimation] = useState(false)

  const goalWeightNumber = useMemo(() => {
    if (!goalWeight?.[0]) return 0

    return Number(goalWeight?.[0])
  }, [goalWeight?.[0]])

  const currentWeightNumber = useMemo(() => {
    if (!currentWeight?.[0]) return 0

    if (currentWeight?.[1] !== goalWeight?.[1] && goalWeight?.[1] === 'lbs') {
      return Number(currentWeight?.[0]) * 2.2
    } else if (currentWeight?.[1] !== goalWeight?.[1] && goalWeight?.[1] === 'kg') {
      return Number(currentWeight?.[0]) * 0.4536
    } else {
      return Number(currentWeight?.[0])
    }
  }, [currentWeight?.[0], goalWeight?.[1]])

  const newDateFit = useMemo(() => {
    if (!currentWeightNumber || !goalWeightNumber || !goalWeight) return new Date()
    const currentDate = new Date()

    if (goalWeight?.[1] === 'lbs') {
      return new Date(
        currentDate.setDate(
          currentDate.getDate() +
            Number(
              (Math.abs(Number(currentWeightNumber) - Number(goalWeightNumber)) / 0.315).toFixed(0),
            ),
        ),
      )
    } else {
      return new Date(
        currentDate.setDate(
          currentDate.getDate() +
            Number(
              (Math.abs(Number(currentWeightNumber) - Number(goalWeightNumber)) / 0.143).toFixed(0),
            ),
        ),
      )
    }
  }, [goalWeightNumber, currentWeightNumber, goalWeight?.[1]])

  const weight = useMemo(() => {
    if (!currentWeightNumber || !goalWeightNumber) return 0

    return Number(currentWeightNumber.toFixed(0)) - Number(goalWeightNumber.toFixed(0))
  }, [currentWeightNumber, goalWeightNumber])

  const isEventExists = useMemo(() => {
    return event && event !== 'No Events Any Time Soon' && eventDate
  }, [event, eventDate])

  // const eventIconUrl = useMemo(() => {
  //   if (typeof event !== 'string' || !(event in eventIcons)) return

  //   return eventIcons[event as keyof typeof eventIcons]
  // }, [event])

  const eventName = useMemo(() => {
    if (typeof event !== 'string' || !(event in eventNames)) return

    return eventNames[event as keyof typeof eventNames]
  }, [event])

  const isEventIsInInterval = useMemo(() => {
    if (!eventDate || typeof eventDate !== 'string' || !newDateFit) return false

    const currentDate = new Date()
    if (new Date(eventDate) < currentDate || new Date(eventDate) > newDateFit) return false

    return true
  }, [eventDate, newDateFit])

  const weightLossForEvent = useMemo(() => {
    if (!eventDate || typeof eventDate !== 'string') return
    const currentDate = new Date()
    if (new Date(eventDate) < currentDate || new Date(eventDate) > newDateFit) return 0

    const eventDifferenceInMilliseconds = new Date(eventDate).getTime() - currentDate.getTime()
    const eventDifferenceInDays = Math.ceil(eventDifferenceInMilliseconds / (1000 * 60 * 60 * 24))

    if (goalWeight?.[1] === 'lbs') {
      return Math.ceil(eventDifferenceInDays * 0.315)
    } else {
      return Math.ceil(eventDifferenceInDays * 0.143)
    }
  }, [eventDate, goalWeight?.[1]])

  const weightEvent = useMemo(() => {
    if (!weightLossForEvent || !goalWeightNumber || !currentWeightNumber) return 0

    if (currentWeightNumber >= goalWeightNumber) return currentWeightNumber - weightLossForEvent
    if (currentWeightNumber <= goalWeightNumber) return currentWeightNumber + weightLossForEvent
  }, [weightLossForEvent, goalWeightNumber, currentWeightNumber])

  const eventPercent = useMemo(() => {
    if (!eventDate || !event || !newDateFit || typeof eventDate !== 'string') return 0

    const currentDate = new Date()
    if (new Date(eventDate) < currentDate || new Date(eventDate) > newDateFit) return 0

    const differenceInMilliseconds = newDateFit.getTime() - currentDate.getTime()
    const eventDifferenceInMilliseconds = new Date(eventDate).getTime() - currentDate.getTime()

    const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24))
    const eventDifferenceInDays = Math.ceil(eventDifferenceInMilliseconds / (1000 * 60 * 60 * 24))

    const percent = differenceInDays / 100

    return eventDifferenceInDays / percent
  }, [eventDate, event, newDateFit])

  useEffect(() => {
    if (props.active) {
      setTimeout(() => {
        setLoadAnimation(true)
      }, 500)
    } else {
      setLoadAnimation(false)
    }
  }, [props.active])

  useEffect(() => {
    const path = document.getElementById('graphLine')
    const point = document.getElementById('point')
    const verticalLine = document.getElementById('verticalLine')

    if (!path || !point || !verticalLine || !eventPercent || !(path instanceof SVGPathElement))
      return

    const length = path.getTotalLength()
    const distance = (length * eventPercent) / 100
    const { x, y } = path.getPointAtLength(distance)

    point.setAttribute('cx', `${x}`)
    point.setAttribute('cy', `${y}`)
    verticalLine.setAttribute('x1', `${x}`)
    verticalLine.setAttribute('x2', `${x}`)
  }, [eventPercent])

  return (
    <>
      <p className="text-dark text-center text-xl2 font-semibold leading-8 -mt-2">
        We predict you’ll be <br />{' '}
        <span className="text-green text-xl2 font-bold font-girloy">
          {goalWeight?.[0]} {goalWeight?.[1]}
        </span>{' '}
        by{' '}
        <span className="text-green text-xl2 font-bold font-girloy">
          {newDateFit.toLocaleString('en-us', { day: 'numeric', month: 'long' })}
        </span>{' '}
        <br />
        {isEventExists && isEventIsInInterval && (weight > 0 ? 'and lose' : 'and gain')}
      </p>

      {isEventExists && (
        <div className="base-graph-event__wrap">
          <div className="base-graph-event">
            <p className="text-dark text-xl2 font-semibold">
              {isEventIsInInterval && (
                <>
                  <span className="font-bold text-green">
                    ~{weightLossForEvent} {goalWeight?.[1]}
                  </span>{' '}
                  by the{' '}
                </>
              )}
              {!isEventIsInInterval && 'Just in time for the '}
              <span className="font-bold text-green">{eventName}</span>
            </p>
            {/* <img src={eventIconUrl} alt="event" /> */}
          </div>
        </div>
      )}

      {weight < 0 && (
        <div className="rounded-r16 p-4 pl-12 pt-10 mt-6">
          <div
            className={`${loadAnimation ? 'base-graph--active' : ''} base-graph base-graph--gain  relative w-[271px] pb-1 `}
          >
            <svg
              viewBox="0 0 303 169"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              className="w-full"
            >
              <line className="base-horizontal-line" x1="0" y1="1" x2="303" y2="1"></line>
              <line className="base-horizontal-line" x1="0" y1="42.75" x2="303" y2="42.75"></line>
              <line className="base-horizontal-line" x1="0" y1="85.5" x2="303" y2="85.5"></line>
              <line className="base-horizontal-line" x1="0" y1="128.25" x2="303" y2="128.25"></line>
              {/* <line className="base-horizontal-line" x1="0" y1="169" x2="303" y2="169"></line> */}

              <line className="base-vertical-line" x1="45" y1="1" x2="45" y2="168"></line>
              <line className="base-vertical-line" x1="90" y1="1" x2="90" y2="168"></line>
              <line className="base-vertical-line" x1="135" y1="1" x2="135" y2="168"></line>
              <line className="base-vertical-line" x1="180" y1="1" x2="180" y2="168"></line>
              <line className="base-vertical-line" x1="225" y1="1" x2="225" y2="168"></line>
              <line className="base-vertical-line" x1="270" y1="1" x2="270" y2="168"></line>
            </svg>
            <div className="absolute w-0 overflow-hidden top-0 left-0 h-full base-line z-10">
              <svg
                viewBox="0 0 303 171"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                className="absolute top-0 left-0 w-[271px]"
              >
                <path
                  id="graphLine"
                  d="M2 169C24.5 161.5 51.5 112.5 135.5 85.5C216.666 59.411 188 3 301 2.99417"
                  stroke="url(#paint0_linear_20274_146770_GainWeightChartLine)"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  opacity="0.15"
                  d="M301 0.994171C188 1 216.666 57.411 135.5 83.5C51.5 110.5 24.5 159.5 2 167L0 167V169H303V0.994171H301Z"
                  fill="url(#paint0_linear_2497_6786)"
                ></path>

                <defs>
                  <linearGradient
                    id="paint0_linear_2497_6786"
                    x1="133.5"
                    y1="0"
                    x2="133.5"
                    y2="168"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#171717"></stop>
                    <stop offset="1" stopColor="#171717" stopOpacity="0"></stop>
                  </linearGradient>
                  <linearGradient
                    id="paint0_linear_20274_146770_GainWeightChartLine"
                    x1="1.23485"
                    y1="2.81298"
                    x2="224.877"
                    y2="43.4267"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#FF0000"></stop>
                    <stop offset="0.427083" stopColor="#FFE600"></stop>
                    <stop offset="1" stopColor="#30E3B7"></stop>
                  </linearGradient>
                </defs>
                {isEventExists && isEventIsInInterval && (
                  <>
                    <line
                      id="verticalLine"
                      className="base-line-event"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="171"
                    ></line>
                    <circle id="point" className="base-circle-event" cx="0" cy="0" r="8"></circle>
                  </>
                )}
                <line
                  id="verticalEndLine"
                  className="base-line-event"
                  x1="301"
                  y1="0"
                  x2="301"
                  y2="171"
                ></line>
              </svg>
            </div>
            <div className="base-horizontal-cord"></div>
            <div className="base-vertical-cord"></div>
            <p className="absolute top-0 -left-2 text-xs2 text-[#171717b8] -translate-x-full -translate-y-1/2">
              {goalWeight?.[0]}
            </p>
            <p className="absolute top-1/4 -left-2 text-xs2 text-[#171717b8] -translate-x-full -translate-y-1/2">
              {(((+goalWeightNumber + currentWeightNumber) / 2 + goalWeightNumber) / 2).toFixed(0)}
            </p>
            <p className="absolute top-1/2 -left-2 text-xs2 text-[#171717b8] -translate-x-full -translate-y-1/2">
              {((+goalWeightNumber + currentWeightNumber) / 2).toFixed(0)}
            </p>
            <p className="absolute top-28 -left-2 text-xs2 text-[#171717b8] -translate-x-full -translate-y-1/1">
              {(((+goalWeightNumber + currentWeightNumber) / 2 + currentWeightNumber) / 2).toFixed(
                0,
              )}
            </p>
            <p className="absolute bottom-0 -left-2 text-xs2 text-[#171717b8] -translate-x-full translate-y-1/2">
              {currentWeightNumber}
            </p>
            <Tooltip
              place="top-end"
              className="base-event-tooltip base-goal goal"
              anchorSelect="#verticalEndLine"
              isOpen={true}
              offset={12}
            >
              Goal {goalWeight?.[0] + ' ' + goalWeight?.[1]}
            </Tooltip>
            {isEventExists && isEventIsInInterval && (
              <Tooltip
                place="top-start"
                className="base-event-tooltip base-goal event"
                anchorSelect="#verticalLine"
                isOpen={true}
                offset={6}
              >
                {eventName} · {weightEvent} {goalWeight?.[1]}
              </Tooltip>
            )}
          </div>
          <div className="flex items-center justify-between pt-2">
            <p className="text-xs2 opacity-75">Today</p>
            <p className="text-xs2 opacity-75">
              {newDateFit.toLocaleString('en-us', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              })}
            </p>
          </div>
        </div>
      )}
      {weight >= 0 && (
        <div className="rounded-r16 p-4 pl-12 mt-6 pt-10">
          <div
            className={`${loadAnimation ? 'base-graph--active' : ''} base-graph relative w-[271px] pb-1`}
          >
            <svg
              viewBox="0 0 303 168"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              className="w-full charts-common_graphArea__gIM_r personalized-weight-graph-brand-palette-animations_graphArea__r78sZ"
            >
              <line className="base-horizontal-line" x1="0" y1="1" x2="303" y2="1"></line>
              <line className="base-horizontal-line" x1="0" y1="42.75" x2="303" y2="42.75"></line>
              <line className="base-horizontal-line" x1="0" y1="85.5" x2="303" y2="85.5"></line>
              <line className="base-horizontal-line" x1="0" y1="128.25" x2="303" y2="128.25"></line>
              {/* <line className="base-horizontal-line" x1="0" y1="168" x2="303" y2="168"></line> */}

              <line className="base-vertical-line" x1="45" y1="1" x2="45" y2="168"></line>
              <line className="base-vertical-line" x1="90" y1="1" x2="90" y2="168"></line>
              <line className="base-vertical-line" x1="135" y1="1" x2="135" y2="168"></line>
              <line className="base-vertical-line" x1="180" y1="1" x2="180" y2="168"></line>
              <line className="base-vertical-line" x1="225" y1="1" x2="225" y2="168"></line>
              <line className="base-vertical-line" x1="270" y1="1" x2="270" y2="168"></line>
            </svg>
            <div className="absolute w-0 overflow-hidden top-0 left-0  h-full base-line">
              <svg
                viewBox="0 0 303 171"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                className="absolute top-0 left-0 w-[271px] charts-common_graphLine__hUnvs personalized-weight-graph-brand-palette-animations_graphLine__8Rkm_"
              >
                <path
                  id="graphLine"
                  d="M2 2C6.93399 2 10 2.5 14 4.5C36.0767 15.5383 3 29 85 58C152.765 81.9658 188 169 301 168.994"
                  stroke="url(#paint0_linear_19971_139592_LoseWeightChartLine)"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  opacity="0.2"
                  d="M301 166.994C188 167 152.765 79.9658 85 56C3 27 36.0767 13.5383 14 2.5C10 0.5 6.93399 0 2 0H0V168H303V166.994H301Z"
                  fill="url(#paint0_linear_2497_6786)"
                ></path>
                <defs>
                  <linearGradient
                    id="paint0_linear_2497_6786"
                    x1="133.5"
                    y1="0"
                    x2="133.5"
                    y2="168"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#171717"></stop>
                    <stop offset="1" stopColor="#171717" stopOpacity="0"></stop>
                  </linearGradient>
                  <linearGradient
                    id="paint0_linear_19971_139592_LoseWeightChartLine"
                    x1="1.23485"
                    y1="1.81773"
                    x2="224.962"
                    y2="42.2063"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#FF0000"></stop>
                    <stop offset="0.427083" stopColor="#FFE600"></stop>
                    <stop offset="1" stopColor="#30E3B7"></stop>
                  </linearGradient>
                </defs>
                {isEventExists && isEventIsInInterval && (
                  <>
                    <line
                      id="verticalLine"
                      className="base-line-event"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="169"
                    ></line>
                    <circle id="point" className="base-circle-event" cx="10" cy="10" r="8"></circle>
                  </>
                )}
                <line
                  id="verticalEndLine"
                  className="base-line-event"
                  x1="301"
                  y1="85.5"
                  x2="301"
                  y2="171"
                ></line>
              </svg>
            </div>
            <div className="base-horizontal-cord"></div>
            <div className="base-vertical-cord"></div>
            <p className="absolute top-0 -left-2 text-xs2 text-[#171717b8] -translate-x-full -translate-y-1/2">
              {currentWeightNumber}
            </p>
            <p className="absolute top-1/4 -left-2 text-xs2 text-[#171717b8] -translate-x-full -translate-y-1/2">
              {(((+goalWeightNumber + currentWeightNumber) / 2 + currentWeightNumber) / 2).toFixed(
                0,
              )}
            </p>
            <p className="absolute top-1/2 -left-2 text-xs2 text-[#171717b8] -translate-x-full -translate-y-1/2">
              {((+goalWeightNumber + currentWeightNumber) / 2).toFixed(0)}
            </p>
            <p className="absolute top-28 -left-2 text-xs2 text-[#171717b8] -translate-x-full -translate-y-1/1">
              {(((+goalWeightNumber + currentWeightNumber) / 2 + goalWeightNumber) / 2).toFixed(0)}
            </p>
            <p className="absolute bottom-0 -left-2 text-xs2 text-[#171717b8] -translate-x-full translate-y-1/2">
              {goalWeight?.[0]}
            </p>
            <Tooltip
              place="top-start"
              className="base-event-tooltip base-goal goal"
              anchorSelect="#verticalEndLine"
              isOpen={true}
              offset={6}
            >
              Goal {goalWeight?.[0] + ' ' + goalWeight?.[1]}
            </Tooltip>
            {isEventExists && isEventIsInInterval && (
              <Tooltip
                place="top-end"
                className="base-event-tooltip base-goal event"
                anchorSelect="#verticalLine"
                isOpen={true}
                offset={6}
              >
                {eventName} · {weightEvent} {goalWeight?.[1]}
              </Tooltip>
            )}
          </div>
          <div className="flex items-center justify-between pt-2">
            <p className="text-xs2 opacity-75">Today</p>
            <p className="text-xs2 opacity-75">
              {newDateFit.toLocaleString('en-us', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              })}
            </p>
          </div>
        </div>
      )}
    </>
  )
}
