import { PaywallStatus } from '@/components/Paywall/Paywall'
import { formatPlan } from '@/helpers/formatPlan'

export function getActivePlanPrice() {
  const paywallStatusString = sessionStorage.getItem('paywallStatus') ?? ''
  const paywallStatus = paywallStatusString
    ? (JSON.parse(paywallStatusString) as PaywallStatus)
    : { currentStep: 0 }
  const planObj = sessionStorage.getItem('activePlan')
    ? JSON.parse(sessionStorage.getItem('activePlan') ?? '')
    : ''
  const isFreeTrial = paywallStatus.currentStep >= 2
  const isDiscount = paywallStatus.currentStep === 1
  const plan = formatPlan(planObj, isDiscount, isFreeTrial)
  const originalPrice = plan.name === '1-Week' ? 38.95 : plan.originalPrice
  const discountedPrice = isFreeTrial ? 1 : plan.discountedPrice
  const predictedPrice = parseFloat((discountedPrice + originalPrice * 1.42).toFixed(2))
  return { price: discountedPrice, predictedPrice }
}
