class _SessionStorageService {
  get(itemName: string) {
    const item = sessionStorage.getItem(itemName)
    const numPatt = /^\d+$/
    const jsonPatt = /[[{].*[}\]]/

    try {
      if (!item) return null

      if (jsonPatt.test(item)) return JSON.parse(item)
      if (numPatt.test(item)) return parseFloat(item)

      return item
    } catch {
      return null
    }
  }

  set(itemName: string, item: any) {
    if (typeof item === 'object') {
      sessionStorage.setItem(itemName, JSON.stringify(item))
    } else {
      sessionStorage.setItem(itemName, item)
    }
  }

  remove(itemName: string) {
    sessionStorage.removeItem(itemName)
  }
}

export const SessionStorageService = new _SessionStorageService()
